import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

export const ControlledAccordion = ({ activeIndices, setActiveIndices, className = '', items = [] }) => {
  const handleToggle = (index) => {
    const stringIndex = `${index}`;
    setActiveIndices(
      (prevActiveIndices) =>
        prevActiveIndices.includes(stringIndex)
          ? prevActiveIndices?.filter((i) => i !== stringIndex) // Remove stringIndex if already active
          : [...prevActiveIndices, stringIndex], // Add stringIndex if not active
    );
  };

  return <AccordionUI items={items} className={className} activeIndices={activeIndices} onClick={handleToggle} />;
};

ControlledAccordion.propTypes = {
  activeIndices: PropTypes.arrayOf(PropTypes.string),
  setActiveIndices: PropTypes.func,
  className: PropTypes.string,
  items: PropTypes.array,
};

const AccordionUI = ({ items, className, activeIndices, onClick }) => {
  if (!items.length) return null;

  return (
    <div className={`accordion ${className}`}>
      {items.map((item, i) => (
        <AccordionItem
          key={i}
          index={i}
          title={item.title}
          content={item.content}
          isActive={activeIndices.includes(`${i}`)}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

AccordionUI.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  activeIndices: PropTypes.array,
  onClick: PropTypes.func,
};

const AccordionItem = ({ index, title, content, isActive, onClick }) => (
  <Card className={`${isActive ? 'active' : ''}`}>
    <Card.Header onClick={() => onClick(index)}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <span>{title}</span>
        <p className="mb-0 ml-2">{isActive ? '-' : '+'}</p>
      </div>
    </Card.Header>
    {isActive && <Card.Body>{content}</Card.Body>}
  </Card>
);

AccordionItem.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.any,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};
