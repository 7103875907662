import { Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getParkSelector } from '../../Redux/selectors';
import { parkNameWithoutOperator } from '../../utils';

export const ParkGalleryModal = ({ show, onHide, images }) => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));
  const { PARKNAME, OPERATOR } = parkData;

  const parkImgs = images?.map((img, i) => <img key={i} src={img} alt={PARKNAME} />);

  return (
    <Modal show={show} onHide={onHide} centered scrollable dialogClassName="park-gallery-modal">
      <Modal.Header closeButton className="pl-4 mb-0 h3">
        {parkNameWithoutOperator(PARKNAME, OPERATOR)} Gallery
      </Modal.Header>
      <Modal.Body className="px-4 pt-3 text-center">
        <Row xs={1} md={2}>
          {parkImgs.map((img, index) => (
            <Col key={index} className="park-page-gallery">
              {img}
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
};
