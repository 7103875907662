export const isBookingOpen = (config) => {
  const now = Date.now();
  return now > Date.parse(config.bookingOpenFrom) && now < Date.parse(config.bookingOpenUntil);
};

export const applyFilters = (parks, filters) => {
  return parks.filter((park) => {
    return Object.entries(filters).every(([key, value]) => {
      return value === 'All' || park[key]?.trim() === value?.trim();
    });
  });
};

export function parkNameWithoutOperator(parkName, operator) {
  return parkName.replace(operator, '').trim() || parkName.trim();
}
