import {
  ADD_CONFIG,
  ADD_FAQDATA,
  ADD_HIWDATA,
  ADD_HOMEDATA,
  ADD_PARK,
  ADD_SAVEDPARK,
  ADD_THEPARKS,
  ADD_ATTRACTIONS,
} from './action-types';

export const addConfig = (dispatch, json) => {
  return dispatch({
    type: ADD_CONFIG,
    payload: json,
  });
};

export const addTheParks = (dispatch, json) => {
  return dispatch({
    type: ADD_THEPARKS,
    payload: json,
  });
};

export const addPark = (dispatch, pid, data) => {
  return dispatch({
    type: ADD_PARK,
    pid,
    data,
  });
};

export const addSavedPark = (dispatch, pid) => {
  return dispatch({
    type: ADD_SAVEDPARK,
    data: pid,
  });
};

export const addAttractions = (dispatch, json) => {
  return dispatch({
    type: ADD_ATTRACTIONS,
    payload: json,
  });
};

export const addHiwData = (dispatch, json) => {
  return dispatch({
    type: ADD_HIWDATA,
    payload: json,
  });
};

export const addHomeData = (dispatch, json) => {
  return dispatch({
    type: ADD_HOMEDATA,
    payload: json,
  });
};

export const addFaqData = (dispatch, json) => {
  return dispatch({
    type: ADD_FAQDATA,
    payload: json,
  });
};
