import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  accommodationData,
  binaryArrayIntoIndexedAndFilteredItems,
  integerToBinaryArrayOfMinlength,
} from '../../Data/exports';
import { getParkSelector } from '../../Redux/selectors';

export const Accommodation = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { ACCOMODATION, PARK_ACCOMODATION_CONTENT } = parkData;

  const accommodationBinaryArray = integerToBinaryArrayOfMinlength(ACCOMODATION, 10);
  const accommodationIndexes = binaryArrayIntoIndexedAndFilteredItems(accommodationBinaryArray);

  const hasAccommodations = accommodationIndexes.length > 0;

  return (
    <Row xs={1} lg={2}>
      <Col
        dangerouslySetInnerHTML={{
          __html: PARK_ACCOMODATION_CONTENT,
        }}
      />
      {hasAccommodations && (
        <Col>
          <Row xs={1} sm={2}>
            {accommodationIndexes.map((i) => {
              const [key, text] = accommodationData[i];
              return (
                <Col key={i} className="d-flex align-items-center pb-2">
                  <span className="icon-font size-300 mr-2" data-char={key}>
                    {key}
                  </span>
                  {text}
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
    </Row>
  );
};
