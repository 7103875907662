import React, { useEffect, useState } from 'react';
import { Col, Container, Row, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { IoImages } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { ControlledAccordion } from '../Components/Common/CustomAccordion';
import { HeaderNav } from '../Components/Common/HeaderNav';
import Icon from '../Components/Common/Icon';
import { LineThrough } from '../Components/Common/LineThrough';
import SavePark from '../Components/Common/SavePark';
import ScrollToTop from '../Components/Common/ScrollToTop';
import { useParkTabConfigurations } from '../Components/Park/useParkTabConfigurations';
import { ParkGalleryModal } from '../Components/Parks/ParkGalleryModal';
import { useHandleModalState } from '../Hooks/useHandleModalState';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { getConfigSelector, getParkSelector } from '../Redux/selectors';
import { parkNameWithoutOperator } from '../utils';
import { useRemoveBrokenImages } from '../Hooks/useRemoveBrokenImages';

function Park() {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));
  const config = useSelector(getConfigSelector);

  const { width } = useWindowDimensions();
  const isDesktop = width > 992;

  const { show, onHide, onOpen } = useHandleModalState();

  const { ADD1, ADD2, COUNTY, POSTCODE, PARKNAME, PARKDESC, OPERATOR, imgUrls } = parkData;

  const { validImages } = useRemoveBrokenImages(imgUrls);

  const imagesToUse = validImages.length > 0 ? validImages : [imgUrls[0]]
  const parkImgs = imagesToUse?.map((img, i) => (
    <img key={i} src={img} alt={`${PARKNAME} ${i + 1}`} onClick={onOpen} className="cursor-pointer" />
  ));

  const numberOfImages = imgUrls?.length;

  const { tabConfigurations, firstEventKey } = useParkTabConfigurations();

  const formatAddressComponent = (component) =>
    component
      ?.split(',')
      .map((part) => part.trim())
      .join(', ') || '';

  const addressText = [ADD1, ADD2, COUNTY, POSTCODE]
    .filter(Boolean)
    .map((str) => formatAddressComponent(str))
    .join(', ');

  const [activeDesktopTab, setActiveDesktopTab] = useState(firstEventKey);
  const [activeMobileTabs, setActiveMobileTabs] = useState(['1']);

  const accordionItems = tabConfigurations.map(({ tabName, content }) => ({
    title: tabName,
    content,
  }));

  useEffect(() => {
    if (isDesktop) setActiveDesktopTab(firstEventKey);
    if (!isDesktop) setActiveMobileTabs(['0']);
  }, [firstEventKey, isDesktop]);

  return (
    <>
      <Helmet>
        <title>{`${PARKNAME} - Club £${config.promotion} Holidays`}</title>
        <meta name="description" content={`${PARKDESC.replace(/<[^>]*>?/gm, '')}`} />
        <body className="bg-white" />
      </Helmet>
      <ScrollToTop />
      <Container fluid className="bg-light pb-4">
        <Container>
          <Row className="align-items-center">
            <Col xs={6} md={2}>
              <Link to="/parks" className="text-decoration-none" title="Go Back">
                <Icon icon="left" variant="dark" size="200" />
              </Link>
            </Col>
            <Col xs={12} md={8} className="py-3 py-md-5 text-center order-last">
              <h1 className="mb-0">{parkNameWithoutOperator(PARKNAME, OPERATOR)}</h1>
            </Col>
            <Col xs={6} md={2} className="text-right order-md-last">
              <SavePark pid={pid} showLabel />
            </Col>
          </Row>

          <LineThrough>
            <div className="px-3 px-md-5">
              <span className="font-weight-bold">{OPERATOR}</span>
              <br />
              {addressText}
            </div>
          </LineThrough>

          <Row>
            <Col lg={6}>
              <div className="slider-container pb-0">
                <Slider
                  {...{ dots: false, infinite: true, speed: 500, lazyLoad: true }}
                  className="gallery-slider mb-5 border-radius"
                >
                  {parkImgs}
                </Slider>
                {imagesToUse.length > 0 && (
                  <div className="slider-overlay px-3" onClick={onOpen}>
                    <span className="pr-2" style={{ marginBottom: '3px' }}>
                      <IoImages />
                    </span>
                    <p>{numberOfImages}</p>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6} className="pl-lg-5 lead" dangerouslySetInnerHTML={{ __html: PARKDESC }} />
          </Row>
        </Container>
      </Container>

      <TabContainer activeKey={activeDesktopTab}>
        <Container fluid className="p-0 border-bottom-2 bg-light">
          <HeaderNav
            tabConfigurations={tabConfigurations}
            className="d-none d-lg-flex"
            setCurrentTab={setActiveDesktopTab}
          />
        </Container>

        {!isDesktop && (
          <ControlledAccordion
            activeIndices={activeMobileTabs}
            setActiveIndices={setActiveMobileTabs}
            items={accordionItems}
          />
        )}

        {isDesktop && (
          <Container fluid className="bg-white d-flex flex-grow-1">
            <Container className="py-5">
              <TabContent>
                {tabConfigurations.map(({ eventKey, content }) => (
                  <TabPane key={eventKey} eventKey={eventKey}>
                    {content}
                  </TabPane>
                ))}
              </TabContent>
            </Container>
          </Container>
        )}
      </TabContainer>
      {show && <ParkGalleryModal show={show} onHide={onHide} images={validImages} />}
    </>
  );
}

export default Park;
