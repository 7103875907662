import { useEffect, useState } from 'react';

export const useRemoveBrokenImages = (images) => {
  const [validImages, setValidImages] = useState([]);

  const filterValidImages = async (imgUrls, batchSize = 3) => {
    const checkImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve({ url, valid: true });
        img.onerror = () => resolve({ url, valid: false });
      });
    };

    const clonedImages = [...imgUrls];
    // Check left and right, because these are used in a circular carousel
    while (clonedImages.length > 0) {
      const leftImages = clonedImages.splice(0, batchSize);
      const rightImages = clonedImages.splice(-batchSize);
      
      const batch = [...leftImages, ...rightImages];
    
      const results = await Promise.allSettled(batch.map(checkImage));
      const validUrls = results.filter((r) => r.status === 'fulfilled' && r.value.valid).map((r) => r.value.url);

      // Incrementally update state to allow progressive rendering
      setValidImages((prev) => [...prev, ...validUrls]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const filterImages = async () => {
      if (validImages.length > 0 || !images || images.length === 0 || !isMounted) return;
      await filterValidImages(images);
    };

    filterImages();

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  return { validImages };
};
