import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { getConfigSelector } from '../../Redux/selectors';
import { isBookingOpen } from '../../utils';

function Header() {
  const location = useLocation();
  const [expandNav, setExpandNav] = useState(false);
  const config = useSelector(getConfigSelector);
  const nineFiftyOn = config.promotion === '9.50';

  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview');
    }
    setExpandNav(false);
  }, [location]);

  return (
    <Navbar expand="lg" expanded={expandNav} bg="dark" variant="dark" sticky="top" className="p-0">
      <Container className="p-0 pr-3">
        <Navbar.Brand>
          <Link to="/">
            <span className={nineFiftyOn ? 'club-logo' : 'club-logo15'}>Club 950</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar" id="nav-toggle" onClick={() => setExpandNav(!expandNav)}>
          <span />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar">
          <Nav className="ml-auto p-3 p-lg-0">
            <NavLink className="nav-link mr-3 p-0" to="/" exact>
              <span className="icon-font size-175 font-weight-normal line-height-fix">@</span>
            </NavLink>
            <NavLink className="nav-link mr-3" to="/parks">
              Browse Parks
            </NavLink>
            <NavLink className="nav-link mr-3" to="/how-it-works">
              How it Works
            </NavLink>
            <NavLink className="nav-link mr-3" to="/faq">
              FAQs
            </NavLink>
            <NavLink className="nav-link mr-3" to="/how-it-works/ferries">
              Ferries
            </NavLink>
            {isBookingOpen(config) && (
              <a
                href="https://www.ukholidaysinthesun.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mt-4 mt-lg-0"
              >
                Book Now
              </a>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
